import { FC, PropsWithChildren, CSSProperties } from "react";
import styles from "./styles.module.css";

export interface GroupProps {
  align?: CSSProperties["alignItems"];
  gap?: CSSProperties["gap"];
  justify?: CSSProperties["justifyContent"];
  className?: string;
}

const Group: FC<PropsWithChildren<GroupProps>> = ({ align, gap, justify, className, ...props }) => {
  const style = {
    "--group-align": align,
    "--group-gap": gap,
    "--group-justify": justify,
  } as React.CSSProperties;
  return (
    <div className={`${styles.groupRoot} ${className ? className : ""}`} style={style}>
      {props.children}
    </div>
  );
};

export default Group;
