import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getCreateOrderValues, getValidConditions, getValidSizes } from "services/Orders";
import { getCompanyById, getCompanyOrderTemplates } from "services/Company";

import amountDeviationOptionValues from "constants/AmountDeviationValues";
import UserContext from "contexts/UserContext";
import SortDropDownOptions from "utils/SortDropDownOptions";
import Grid from "components/Grid/Grid";
import Dropdown from "components/Dropdown/Dropdown";
import TextField from "components/TextField/TextField";

import TextfieldDropdown from "components/TextfieldDropdown/TextfieldDropdown";

import CTA from "components/_Reviver/atoms/CTA";

import "./OrderStep.css";

export default function OrderDetails(props) {
  const { t } = useTranslation();

  const { onChange, values, fieldValidationErrors, orderTemplate, setOrderTemplate } = props;

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [salesItemOptions, setSalesItemsOptions] = useState([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [toolOptions, setToolOptions] = useState([]);
  const [selectedTool, setSelectedTool] = useState(null);
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [orderTemplateOptions, setOrderTemplateOptions] = useState([]);
  const [amountDeviationOptions] = useState(amountDeviationOptionValues);
  const [selectedAmountDeviation, setSelectedAmountDeviation] = useState(null);
  const [deviationText, setDeviationText] = useState("");
  const [isOrderTemplateSet, setIsOrderTemplateSet] = useState(false);

  const setDefaultCurrency = useCallback(
    async (currencyOptions) => {
      async function asyncGetCurrentCompany() {
        return await getCompanyById(currentCompany.companyId);
      }
      let company = await asyncGetCurrentCompany();

      //Default currency
      const _defaultCurrency = currencyOptions.find((x) => x.label === company.data.defaultCurrency);
      setSelectedCurrency(_defaultCurrency);
      onChange({
        orderCurrency: {
          id: _defaultCurrency.value,
          value: _defaultCurrency.label,
        },
      });
    },
    [currentCompany.companyId, onChange]
  );

  useEffect(() => {
    let isMounted = true;

    async function loadOptions() {
      async function asyncGetDropdownData() {
        return await getCreateOrderValues();
      }
      async function asyncGetOrderTemplates() {
        const templates = await getCompanyOrderTemplates(currentCompany.companyId);
        return templates.data;
      }

      let dropDownValues = await asyncGetDropdownData();
      let orderTemplatesResults = await asyncGetOrderTemplates();

      //Sales item options
      const _salesItemOptions = dropDownValues.data.salesItems.map((salesItem) => ({
        value: salesItem.id,
        label: t("common.plastic." + salesItem.value),
      }));
      const _sortedSalesItemOptions = SortDropDownOptions(_salesItemOptions);
      setSalesItemsOptions(_sortedSalesItemOptions);

      //Treatment options
      const _treatmentOptions = dropDownValues.data.treatments.map((treatment) => ({
        value: treatment.id,
        label: t("common.treatments." + treatment.value),
      }));
      const _sortedTreatmentOptions = SortDropDownOptions(_treatmentOptions);
      setTreatmentOptions(_sortedTreatmentOptions);

      //Currency options
      const _currencyOptions = dropDownValues.data.currencies.map((currency) => ({
        value: currency.id,
        label: currency.value.toUpperCase(),
      }));
      const _sortedCurrencyOptions = SortDropDownOptions(_currencyOptions);
      setCurrencyOptions(_sortedCurrencyOptions);

      //Tool options
      const _toolOptions = dropDownValues.data.gears.map((gear) => ({
        value: gear.id,
        label: t("common.color." + gear.value),
      }));
      const _sortedToolOptions = SortDropDownOptions(_toolOptions);
      setToolOptions(_sortedToolOptions);

      //Order template options
      const _orderTemplates = orderTemplatesResults.orderTemplates.map((template) => ({
        value: template.templateId,
        label: template.templateName,
        template: template,
      }));
      const _sortedOrderTemplates = SortDropDownOptions(_orderTemplates);
      setOrderTemplateOptions(_sortedOrderTemplates);

      if (!selectedCurrency) await setDefaultCurrency(_currencyOptions);
    }

    if (isMounted) loadOptions();

    return () => {
      isMounted = false;
    };
  }, [t, currentCompany.companyId, setDefaultCurrency, selectedCurrency]);

  const asyncGetValidConditions = useCallback(
    async (salesItemId) => {
      const validConditions = await getValidConditions(salesItemId);
      const _conditionOptions = validConditions.data.map((condition) => ({
        value: condition.id,
        label: t("common.conditions." + condition.condition),
      }));
      const _sortedConditionOptions = SortDropDownOptions(_conditionOptions);
      setConditionOptions(_sortedConditionOptions);

      return _conditionOptions;
    },
    [t]
  );

  const asyncGetValidSizes = useCallback(async (salesItemId, conditionId) => {
    const validSizes = await getValidSizes(salesItemId, conditionId);
    const _sizeOptions = validSizes.data.map((size) => ({
      value: size.id,
      label: size.size,
    }));
    const _sortedSizeOptions = SortDropDownOptions(_sizeOptions);
    setSizeOptions(_sortedSizeOptions);

    return _sizeOptions;
  }, []);

  //Set selected salesitem (used for going back)
  useEffect(() => {
    setSelectedSalesItem(salesItemOptions.find((item) => item.value === values.salesItem.id));
    asyncGetValidConditions(values.salesItem.id);
  }, [salesItemOptions, asyncGetValidConditions, values.salesItem.id]);

  //Set selected condition (used for going back)
  useEffect(() => {
    setSelectedCondition(conditionOptions.find((condition) => condition.value === values.condition.id));
    asyncGetValidSizes(values.salesItem.id, values.condition.id);
  }, [conditionOptions, asyncGetValidSizes, values.salesItem.id, values.condition.id]);

  //Set selected size (used for going back)
  useEffect(() => {
    if (values.size) setSelectedSize(sizeOptions.find((size) => size.value === values.size.id));
  }, [sizeOptions, values.size]);

  //Set selected tool (used for going back)
  useEffect(() => {
    if (values.gear) setSelectedTool(toolOptions.find((tool) => tool.value === values.gear.id));
  }, [toolOptions, values.gear]);

  //Set selected treatment (used for going back)
  useEffect(() => {
    if (values.treatment) setSelectedTreatment(treatmentOptions.find((x) => x.value === values.treatment.id));
  }, [treatmentOptions, values.treatment]);

  //Set selected amountDeviation (used for going back)
  useEffect(() => {
    if (values.amountDeviation > 0)
      setSelectedAmountDeviation(amountDeviationOptions.find((x) => x.value === values.amountDeviation));
  }, [amountDeviationOptions, values.amountDeviation]);

  function resetConditionAndSize() {
    setSelectedCondition(null);
    setConditionOptions([]);

    onChange({
      condition: {
        id: 0,
        value: "",
      },
    });

    resetSize();
  }

  function resetValues() {
    setSelectedSalesItem(null);

    setSelectedCondition(null);
    setConditionOptions([]);

    setSelectedSize(null);
    setSizeOptions([]);

    setSelectedTreatment(null);

    setSelectedTool(null);

    onChange({
      salesItem: {
        id: 0,
        value: "",
      },
      condition: {
        id: 0,
        value: "",
      },
      size: {
        id: 0,
        value: "",
      },
      treatment: null,
      gear: null,
      packaging: {
        boxtype: 0,
        numberOfBoxes: 0,
        amountInEachBox: 0,
        palletPlaces: null,
      },
      companiesToTradeWith: [],
    });
  }

  function resetSize() {
    setSelectedSize(null);
    setSizeOptions([]);

    onChange({
      size: {
        id: 0,
        value: "",
      },
    });
  }

  async function onChangeOrderTemplate(e) {
    setOrderTemplate(e);
    setIsOrderTemplateSet(true);
    // Initialize create order object with template values (also initialize dropdowns)
    const _salesItem = salesItemOptions.find((salesItem) => salesItem.value === e.template.salesItemId);
    const _conditionOptions = await onChangeSalesItem(_salesItem);

    const _condition = _conditionOptions.find((condition) => condition.value === e.template.conditionId);
    const _sizeOptions = await onChangeCondition(_condition, _salesItem);

    const _size = _sizeOptions.find((size) => size.value === e.template.sizeId);
    onChangeSize(_size);

    if (e.template.gearId > 0) {
      const _tool = toolOptions.find((tool) => tool.value === e.template.gearId);
      onChangeTool(_tool);
    } else {
      onChangeTool(null);
    }
    if (e.template.treatmentId > 0) {
      const _treatment = treatmentOptions.find((treatment) => treatment.value === e.template.treatmentId);
      onChangeTreatment(_treatment);
    } else {
      onChangeTreatment(null);
    }

    // Also initiaze object for next steps in wizard..
    onChange({
      companiesToTradeWith: e.template.orderTemplateNetwork.map((network) => network.companyId),
    });
  }

  async function onChangeSalesItem(e) {
    setSelectedSalesItem(e);

    // Condition and Size depends on Fish type (so reset these)
    resetConditionAndSize();
    const conditionOptions = await asyncGetValidConditions(e.value);
    onChange({
      salesItem: {
        id: e.value,
        value: e.label,
      },
    });
    return conditionOptions;
  }

  function onChangeSize(e) {
    setSelectedSize(e);

    onChange({
      size: {
        id: e.value,
        value: e.label,
      },
    });
  }

  async function onChangeCondition(e, selectedSalesItem) {
    setSelectedCondition(e);

    // Size depends on Condition type (so reset this)
    resetSize();

    const sizeOptions = await asyncGetValidSizes(selectedSalesItem.value, e.value);
    onChange({
      condition: {
        id: e.value,
        value: e.label,
      },
    });
    return sizeOptions;
  }

  function onChangeAmountDeviation(e) {
    setSelectedAmountDeviation(e);

    if (e === null) {
      calculateDeviation(values.amount, 0);
      onChange({ amountDeviation: 0 });
      return;
    }

    onChange({ amountDeviation: e.value });
    calculateDeviation(values.amount, e.value);
  }

  function onChangeAmount(e) {
    onChange({ amount: e });
    calculateDeviation(e, values.amountDeviation);
  }

  function calculateDeviation(amount, deviation) {
    if (amount <= 0 || deviation <= 0) {
      setDeviationText("");
      return;
    }

    const _deviation = (amount / 100) * deviation;
    const lowEnd = Number.parseFloat(amount) - _deviation;
    const highEnd = Number.parseFloat(amount) + _deviation;
    setDeviationText(`${lowEnd.toFixed(2)}kg - ${highEnd.toFixed(2)}kg`);
  }

  function onChangeTool(e) {
    setSelectedTool(e);

    if (e === null) {
      onChange({ gear: null });
    } else {
      onChange({
        gear: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeTreatment(e) {
    setSelectedTreatment(e);

    if (e === null) {
      onChange({ treatment: null });
    } else {
      onChange({
        treatment: {
          id: e.value,
          value: e.label,
        },
      });
    }
  }

  function onChangeCurrency(e) {
    setSelectedCurrency(e);
    onChange({
      orderCurrency: {
        id: e.value,
        value: e.label,
      },
    });
  }

  function clearTemplate() {
    setIsOrderTemplateSet(false);
    setOrderTemplate([]);
    setSelectedSalesItem({ value: 0, label: "" });
    resetValues();
  }

  return (
    <Grid className={`${props.className} create-order-container`}>
      <Grid.Row className="align">
        <Grid.Col span={6}>
          <Dropdown
            id="template-options"
            label="Templates"
            options={orderTemplateOptions}
            onChange={(e) => onChangeOrderTemplate(e)}
            value={orderTemplate}
          />
          <CTA disabled={!isOrderTemplateSet} size="sm" id="clear-template" onClick={clearTemplate} fullWidth>
            {t("commonOrders.clearTemplate")}
          </CTA>
        </Grid.Col>
        <Grid.Col span={6}>
          {/* <Checkbox
            id='create-order-promote'
            label={t('commonOrders.promoteOrder')}
            checked={values.promoted}
            onChange={(e) => onChange({ promoted: e.checked })}
          /> */}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-type"
            label={t("common.type")}
            name="salesItem"
            options={salesItemOptions}
            value={selectedSalesItem}
            onChange={(e) => onChangeSalesItem(e)}
            errormessage={fieldValidationErrors.salesItem}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextfieldDropdown
            id="create-order-currencyprice"
            textFieldName="price"
            textfieldLabel={t("common.price") + " (per kg)"}
            textFieldType="number"
            textfieldValue={values.price}
            textfieldOnChange={(e) => onChange({ price: e.target.value })}
            dropdownLabel={t("common.currency")}
            dropdownOptions={currencyOptions}
            dropdownValue={selectedCurrency}
            dropdownOnChange={(e) => onChangeCurrency(e)}
            errormessage={fieldValidationErrors.price}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <TextField
            id="create-order-maxPrice"
            type="number"
            label={t("commonOrders.maxPrice") + " (per kg)"}
            value={values.maxPrice}
            onChange={(e) => onChange({ maxPrice: e.target.value })}
            disabled={values.priceAdjustPercentage}
            errormessage={fieldValidationErrors.maxPrice}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            id="create-order-maxPrice"
            type="number"
            label={t("commonOrders.maxPricePercent") + " (per kg)"}
            value={values.priceAdjustPercentage}
            onChange={(e) => onChange({ priceAdjustPercentage: e.target.value })}
            disabled={values.maxPrice}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <TextfieldDropdown
            id="create-order-amount"
            textFieldName="amount"
            textfieldLabel={t("common.amount") + " (kg)"}
            textFieldType="number"
            textfieldOnChange={(e) => onChangeAmount(e.target.value)}
            textfieldValue={values.amount}
            dropdownLabel={"+/-"}
            dropdownOptions={amountDeviationOptions}
            dropdownValue={selectedAmountDeviation}
            dropdownOnChange={(e) => onChangeAmountDeviation(e)}
            dropdownIsClearable
            dropdownClearValue={() => onChangeAmountDeviation(null)}
            menuPlacement={"top"}
            errormessage={fieldValidationErrors.amount}
          />
        </Grid.Col>
        <Grid.Col span={6} className="vbottom font-family-sans">
          {deviationText && (
            <>
              <p>{t("commonOrders.amountDeviationPre")}</p>
              <p>{deviationText}</p>
            </>
          )}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-condition"
            name="condition"
            label={t("common.class")}
            menuPlacement="top"
            options={conditionOptions}
            value={selectedCondition}
            onChange={(e) => onChangeCondition(e, selectedSalesItem)}
            errormessage={fieldValidationErrors.condition}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-size"
            name="size"
            label={t("common.size")}
            menuPlacement="top"
            options={sizeOptions}
            value={selectedSize}
            onChange={(e) => onChangeSize(e)}
            errormessage={fieldValidationErrors.size}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row className="mbottom10">
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-tool"
            label={t("common.size")}
            options={toolOptions}
            value={selectedTool}
            menuPlacement="top"
            onChange={(e) => onChangeTool(e)}
            isClearable
            clearValue={() => onChangeTool(null)}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-treatment"
            label={t("common.color")}
            options={treatmentOptions}
            value={selectedTreatment}
            menuPlacement="top"
            onChange={(e) => onChangeTreatment(e)}
            isClearable
            clearValue={() => onChangeTreatment(null)}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
