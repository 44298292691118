import React, { FC, useState, useContext, SyntheticEvent } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { ErrorDialogContext } from "contexts/ErrorDialogContext";
import SVG from "components/SVGAsset/SVGAsset";
import CTA from "components/_Reviver/atoms/CTA";
import TextField from "components/TextField/TextField";
import Heading from "components/_Reviver/atoms/Heading";
import styles from "./styles.module.css";
import { HeadersContainer, SimpleHeader } from "components/_Reviver/molecules/Header";
import { createUser } from "services/Login";
import { validateFieldsHelper } from "utils/ValidationHelper";
import { registerUserSchema } from "./RegisterUserValidationSchema";
import Grid from "components/Grid/Grid";
import Stack from "components/_Reviver/atoms/Stack";
import Text from "components/_Reviver/atoms/Text";
import Spinner from "components/Spinner/Spinner";
import PhoneField from "components/PhoneInput/PhoneInput";
import Space from "components/_Reviver/atoms/Space";
import { ValidationErrors } from "types";

interface Props {
  setUserContext: any;
  setRedirection: any;
  userContext: any;
}

const RegisterUser: FC<Props> = ({ setRedirection, setUserContext, userContext }) => {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(userContext.email || "");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  async function asyncCreateUser(firstName: string, lastName: string, email: string, phone: string) {
    return await createUser(firstName, lastName, email, phone);
  }

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const inputChecker = validateFieldsHelper(registerUserSchema(t), {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
      });
      setValidationErrors(inputChecker as ValidationErrors);
      if (Object.keys(inputChecker as ValidationErrors).length === 0) {
        const response = await asyncCreateUser(firstName, lastName, email, phone);
        const id = response.data.id;
        const token = response.data.emailVerificationToken;

        if (id > 0) {
          setUserContext({ token: token });
          setRedirection("NEW_USER_COMPLETED");
        }
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Something went wrong", error: error as Error });
      showErrorDialog(true);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Reviver - Login Check email</title>
        <meta name="description" content="Reviver - Login check email" />
      </Helmet>
      <HeadersContainer>
        <SimpleHeader />
      </HeadersContainer>
      <div className={styles.registerRoot}>
        <div className={styles.registerContent}>
          <figure className={styles.registerCopy}>
            <Stack justify="space-between" gap="60px">
              <Stack gap="30px">
                <Heading styleOrder={5} textWrap="balance">
                  {t("registerCompany.registerUserHeader")}
                </Heading>
                <Text>
                  {t("registerCompany.registerUserText")}
                  <br />
                  <br />
                  {t("registerCompany.registerUserText2")}
                </Text>
              </Stack>
              <Text strong={true}>
                {t("registerCompany.registerUserRegistered")} <a href="/login">{t("common.logIn")}</a>
              </Text>
            </Stack>
          </figure>

          <div className={styles.formPanel}>
            <SVG className={styles.logo} name="reviver-logo" />
            <form onSubmit={handleSubmit}>
              <Spinner loading={loading} />

              <Grid compact={true}>
                <Grid.Row>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-regUser-firstName"
                      label={t("registerCompany.registerUserFirstname")}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                      errormessage={validationErrors.firstname}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextField
                      id="txt-regUser-lastName"
                      label={t("registerCompany.registerUserLastName")}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                      errormessage={validationErrors.lastname}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <TextField
                      id="txt-regUser-email"
                      label={t("registerCompany.registerUserEmail")}
                      defaultValue={userContext.email || ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                      errormessage={validationErrors.email}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <PhoneField
                      id="txt-regUser-phone"
                      placeholder={t("common.phone")}
                      onChange={(e) => setPhone(e)}
                      errormessage={validationErrors.phone}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col span={12}>
                    <Space height="60px" />
                    <CTA
                      id="btn-loginCheckEmail"
                      type="submit"
                      className="submit"
                      size="lg"
                      onClick={handleSubmit}
                      fullWidth={true}
                    >
                      {t("registerCompany.registerUserSubmit")}
                    </CTA>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterUser;
