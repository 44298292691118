import { FC, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import Stack from "../../atoms/Stack";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "@mantine/hooks";
import { Order } from "types";

interface Props {
  orders: Order[];
  createNew: () => void;
}

const ActiveOrdersHeaderBase: FC<PropsWithChildren> = (props) => {
  return (
    <header className={styles.activeOrdersHeaderRoot} {...props}>
      <div className={styles.inner}>{props.children}</div>
    </header>
  );
};

const ActiveOrdersHeader: FC<Props> = (props) => {
  const { orders, createNew } = props;
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width > 767;
  return (
    <ActiveOrdersHeaderBase>
      <Stack>
        <Text size={isMobile ? "md" : "xl"} display="title" tt="uppercase" strong={true}>
          {t("commonOrders.activeRequests")}
        </Text>
        <Text size={isMobile ? "sm" : "md"} tt="uppercase">
          {orders.length} {t("commonOrders.activeOrders")}
        </Text>
      </Stack>
      <CTA size="lg" intent="primary" onClick={createNew}>
        {t("commonOrders.newOrder")}
      </CTA>
    </ActiveOrdersHeaderBase>
  );
};

export default ActiveOrdersHeader;
