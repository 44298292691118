import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBoxtypeValues } from "services/Orders";
import { validateFieldHelper } from "utils/ValidationHelper";
import { calcMinAmountOfBoxes } from "utils/TransportUtil";
import { createStep3Schema } from "../validation/CreateOrderValidationSchemas";

import Grid from "components/Grid/Grid";
import TextField from "components/TextField/TextField";
import Dropdown from "components/Dropdown/Dropdown";

import "./OrderStep.css";

export default function Packing(props) {
  const { t } = useTranslation();

  const { onChange, values, fieldValidationErrors, setFieldValidationErrors } = props;

  const [noOfBoxes, setNoOfBoxes] = useState(null);
  const [boxtypeItemOptions, setBoxtypeItemOptions] = useState([]);
  const [selectedBoxtype, setSelectedBoxtype] = useState(null);

  useEffect(() => {
    async function asyncGetBoxtypeData() {
      const availableBoxTypes = await getBoxtypeValues();

      const _boxtypeItemOptions = availableBoxTypes.data.boxtypes.map((boxtype) => ({
        value: boxtype.id,
        label: t("common.boxtypes." + boxtype.name),
        maxWeight: boxtype.maxWeight,
      }));
      setBoxtypeItemOptions(_boxtypeItemOptions);
    }

    asyncGetBoxtypeData();
  }, [t]);

  // Set selected boxtype (used for going back and forth)
  useEffect(() => {
    const templateBox = boxtypeItemOptions.find((item) => item.value === values.packaging.boxtype);
    setSelectedBoxtype(templateBox);
  }, [boxtypeItemOptions, values.packaging.boxtype]);

  // Set number of boxes

  useEffect(() => {
    if (selectedBoxtype != null) {
      const nrOfBoxes = calcMinAmountOfBoxes(values.amount, selectedBoxtype.maxWeight);
      setNoOfBoxes(nrOfBoxes);

      onChange({
        packaging: {
          boxtype: selectedBoxtype.value,
          numberOfBoxes: nrOfBoxes,
          amountInEachBox: selectedBoxtype.maxWeight,
          palletPlaces: null,
        },
      });
    }
  }, [selectedBoxtype, boxtypeItemOptions, onChange, values.amount]);

  function onChangeBoxtype(e) {
    // Calculate min amount of boxes required for chosen box type and amount of goods (to help user).
    const nrOfBoxes = calcMinAmountOfBoxes(values.amount, e.maxWeight);
    setNoOfBoxes(nrOfBoxes);

    validateSingleField("boxtype", e.value);
    setSelectedBoxtype(e);

    onChange({
      packaging: {
        ...values.packaging,
        boxtype: e.value,
        numberOfBoxes: nrOfBoxes,
      },
    });
  }

  function onChangeNoBoxes(e) {
    setNoOfBoxes(e);
    onChange({
      packaging: {
        ...values.packaging,
        numberOfBoxes: e,
      },
    });
  }

  //  --- Input Validation ----
  function validateSingleField(propName, propValue) {
    const validationResult = validateFieldHelper(createStep3Schema(t), propName, propValue, fieldValidationErrors);
    setFieldValidationErrors(validationResult);
  }

  return (
    <Grid className={`${props.className} create-order-container`}>
      <Grid.Row>
        <Grid.Col span={6}>
          <Dropdown
            id="create-order-boxtype"
            name="boxtype"
            label={t("common.boxtype")}
            options={boxtypeItemOptions}
            value={selectedBoxtype || ""}
            onChange={(e) => onChangeBoxtype(e)}
            errormessage={fieldValidationErrors.boxtype}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            id="create-order-noboxes"
            name="numberOfBoxes"
            type="number"
            value={noOfBoxes || 0}
            label={t("common.noofboxes")}
            onChange={(e) => onChangeNoBoxes(e.target.value)}
            onBlur={(e) => validateSingleField(e.target.name, e.target.value)}
            errormessage={fieldValidationErrors.numberOfBoxes}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
}
