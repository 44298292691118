import React from "react";
import { useTranslation } from "react-i18next";

import Text from "../../atoms/Text";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import Group from "../../atoms/Group";
import CTA from "../../atoms/CTA";

import styles from "./styles.module.css";

interface Props {
  orderTypeIndicator: any;
  updateOrderHandler: () => void;
  deleteOrderConfirmation: () => void;
  order: any;
}

export default function EditOrderHeader(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.editOrderHeader}>
      <div className={styles.headerInner}>
        <Group align="center" className={styles.orderTitle}>
          <SVGAsset name={props.orderTypeIndicator} width="26" height="26" />
          <Text tt="uppercase" strong={true}>
            {t("commonOrders.orderTypes." + props.order.orderType.type)}
          </Text>
        </Group>
        <div className={styles.headerItems}>
          <div className={styles.ctas}>
            <CTA id="update-order-updateButton" size="sm" onClick={props.updateOrderHandler} intent="affirm">
              {t("commonOrders.updateOrder")}
            </CTA>
            <CTA id="cancel-order-updateButton" size="sm" onClick={props.deleteOrderConfirmation} intent="negate">
              {t("commonOrders.cancel")}
            </CTA>
          </div>
          <Group align="center" className={styles.companyTitle}>
            <SVGAsset name="company-icon" width="26" height="26" />
            <Text tt="uppercase" strong={true} display="title" size="md">
              {props.order.company.companyName}
            </Text>
          </Group>
        </div>
      </div>
    </div>
  );
}
