import React, { useState, useEffect, useContext, useCallback, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { createUser, deleteTeamMember, postChangeRole } from "../../../../services/Settings";
import { getCompanyTeam } from "../../../../services/Company";
import { ErrorDialogContext } from "../../../../contexts/ErrorDialogContext";
import { teamSettingsSchema, editRoleSchema } from "./validation/TeamSettingsValidationSchema";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";

import Grid from "../../../Grid/Grid";
import Modal, { ModalCTAs, ModalContent } from "../../organisms/Modal";
import Textfield from "../../../TextField/TextField";
import Dropdown from "../../../Dropdown/Dropdown";
import UserContext from "../../../../contexts/UserContext";
import ContextPanel from "../../../ContextPanel/ContextPanel";

import CTA from "../../atoms/CTA";
import Text from "../../atoms/Text";
import Heading from "../../atoms/Heading";
import Group from "../../atoms/Group";

import { SettingsFormContent } from ".";
import { ActiveTeamMember, NewMemberRoleType, UserRoleType, ValidationErrors } from "types";

export default function TeamSettings() {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};

  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [activeTeamMembers, setActiveTeamMembers] = useState<ActiveTeamMember[]>([]);
  const [isEditMemberRoleOpen, setIsEditMemberRoleOpen] = useState(false);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [validationErrorsUpdate, setValidationErrorsUpdate] = useState<ValidationErrors>({});

  const asyncGetCompanyTeam = useCallback(async () => {
    const response = await getCompanyTeam(currentCompany.companyId);
    setActiveTeamMembers(response.data.activeUsers);
  }, [currentCompany.companyId]);

  useEffect(() => {
    asyncGetCompanyTeam();
  }, [asyncGetCompanyTeam]);

  // Team Role
  const teamRoleTypes = ["ADMIN", "USER", "VIEWER"] as UserRoleType[];
  function getTeamRoleTypeOptions() {
    const types = teamRoleTypes;
    return types.map((label, value) => ({
      value,
      label,
    }));
  }
  const teamRoleTypeOptions = getTeamRoleTypeOptions();
  const [isNewMemberDialogOpen, setIsNewMemberDialogOpen] = useState(false);
  const [newMemberRoleTypeOptions] = useState(teamRoleTypeOptions);
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [newMemberFirstName, setNewMemberFirstName] = useState("");
  const [newMemberLastName, setNewMemberLastName] = useState("");
  const [selectedNewMemberRoleType, setSelectedNewMemberRoleType] = useState<NewMemberRoleType>();
  const [selectedUserId, setSelectedUserId] = useState();

  function teamRoleTypeHandler(e: any) {
    setSelectedNewMemberRoleType(e);
  }

  function openAddNewMemberDialog() {
    setNewMemberFirstName("");
    setNewMemberLastName("");
    setNewMemberEmail("");
    setSelectedNewMemberRoleType(undefined);
    setIsNewMemberDialogOpen(true);
  }

  const inviteNewMemberHandler = async () => {
    try {
      const inputChecker: any = validateFieldsHelper(teamSettingsSchema(t), {
        firstname: newMemberFirstName,
        lastname: newMemberLastName,
        email: newMemberEmail,
        roleType: selectedNewMemberRoleType?.label,
      });
      setValidationErrors(inputChecker);

      if (Object.keys(inputChecker).length === 0) {
        await createUser(
          newMemberFirstName,
          newMemberLastName,
          newMemberEmail,
          selectedNewMemberRoleType?.label,
          currentCompany.companyId
        );
        asyncGetCompanyTeam();
        setIsNewMemberDialogOpen(false);
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Create user feilet.", error: error as Error });
      showErrorDialog(true);
    }
  };

  // Active member context
  function createActiveMemberContextOptions(index: number, array: any[]) {
    return [
      {
        label: t("settings.edit"),
        action: () => openEditTeamMemberDialog(index, array),
      },
      {
        label: t("settings.delete"),
        action: () => removeTeamMember(index, array),
      },
    ];
  }

  const getTeamRoleOptionByLabel = (label: string) => teamRoleTypeOptions.find((option) => label === option.label);

  function openEditTeamMemberDialog(index: number, array: any[]) {
    const teamMember = array[index];

    const thisRoleType = getTeamRoleOptionByLabel(teamMember.roleType);
    setSelectedNewMemberRoleType(thisRoleType);

    setSelectedUserId(teamMember.id);
    setNewMemberFirstName(teamMember.firstName);
    setNewMemberLastName(teamMember.lastName);
    setNewMemberEmail(teamMember.email);

    setIsEditMemberRoleOpen(true);
  }

  async function removeTeamMember(index: number, array: any[]) {
    try {
      const memberToDelete = array.filter((member) => member === array[index]);
      if (memberToDelete.length > 0) {
        await deleteTeamMember(currentCompany.companyId, memberToDelete[0].id);
        asyncGetCompanyTeam();
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Remove user failed", error: error as Error });
      showErrorDialog(true);
    }
  }

  const editMemberRole = async () => {
    try {
      const inputCheckerUpdate: any = validateFieldsHelper(editRoleSchema(t), {
        roleType: selectedNewMemberRoleType?.label,
      });
      setValidationErrorsUpdate(inputCheckerUpdate);
      if (Object.keys(inputCheckerUpdate).length === 0) {
        await postChangeRole(selectedUserId, selectedNewMemberRoleType?.label, currentCompany.companyId);
        setIsEditMemberRoleOpen(false);
        asyncGetCompanyTeam();
      }
    } catch (error) {
      setErrorDialogOptions({ message: "Edit role feilet.", error: error as Error });
      showErrorDialog(true);
    }
  };

  function closeAddNewTeamMember() {
    setIsNewMemberDialogOpen(false);
    setValidationErrors({});
  }

  function closeEditTeamMember() {
    setIsEditMemberRoleOpen(false);
    setValidationErrorsUpdate({});
  }

  return (
    <>
      <Heading order={2} styleOrder={11} tt="uppercase">
        {t("settings.team")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12} className="top-card">
              <Group justify="space-between">
                <Text size="md" strong={true} display="title" tt="uppercase">
                  {t("settings.activeUsers")}
                </Text>
                <CTA id="settings-team-add" intent="secondary" onClick={openAddNewMemberDialog}>
                  {t("settings.newUser")}
                </CTA>
              </Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <table className="lightblue settings">
                <thead>
                  <tr>
                    <th scope="col" colSpan={3}>
                      {t("common.user")}
                    </th>
                    <th scope="col" colSpan={2}>
                      {t("common.email")}
                    </th>
                    <th scope="col" colSpan={2}>
                      {t("common.role")}
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {activeTeamMembers.map((activeUser, index, array) => (
                    <tr key={`active-member-row-${index}`}>
                      <td data-label={t("common.user")} colSpan={3}>
                        {activeUser.firstName} {activeUser.lastName}
                      </td>
                      <td data-label={t("common.email")} colSpan={2}>
                        {activeUser.email}
                      </td>
                      <td data-label={t("common.role")} colSpan={2}>
                        {activeUser.roleType}
                      </td>
                      <td data-label="Actions">
                        <ContextPanel
                          options={createActiveMemberContextOptions(index, array)}
                          contextType={ContextPanel.contextType.Secondary}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>

      {/* INVITE NEW MEMBER */}
      <Modal open={isNewMemberDialogOpen} onClose={() => closeAddNewTeamMember()} headerText={t("settings.addNewUser")}>
        <ModalContent>
          <Grid>
            <Grid.Row>
              <Grid.Col span={6}>
                <Textfield
                  id="settings-team-firstname"
                  textFieldClassName="mbottom10"
                  label={t("common.firstname")}
                  value={newMemberFirstName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNewMemberFirstName(e.target.value)}
                  errormessage={validationErrors.firstname}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Textfield
                  id="settings-team-lastname"
                  textFieldClassName="mbottom10"
                  label={t("common.lastname")}
                  value={newMemberLastName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNewMemberLastName(e.target.value)}
                  errormessage={validationErrors.lastname}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col span={6}>
                <Textfield
                  id="settings-team-email"
                  textFieldClassName="mbottom10"
                  label={t("common.email")}
                  value={newMemberEmail}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNewMemberEmail(e.target.value)}
                  errormessage={validationErrors.email}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Dropdown
                  id="settings-team-role"
                  dropDownClassNames="mbottom10"
                  label={t("common.role")}
                  options={newMemberRoleTypeOptions}
                  onChange={teamRoleTypeHandler}
                  value={selectedNewMemberRoleType}
                  errormessage={validationErrors.roleType}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ModalContent>
        <ModalCTAs>
          <CTA id="settings-team-cancel" size="lg" intent="secondary" onClick={() => closeAddNewTeamMember()}>
            {t("common.cancel")}
          </CTA>
          <CTA id="settings-team-invite" size="lg" intent="primary" onClick={inviteNewMemberHandler}>
            {t("settings.invite")}
          </CTA>
        </ModalCTAs>
      </Modal>

      <Modal
        open={isEditMemberRoleOpen}
        onClose={() => setIsEditMemberRoleOpen(false)}
        headerText={`${t("settings.editRole")}`}
      >
        <ModalContent>
          <Grid>
            <Grid.Row>
              <Grid.Col span={6}>
                <Dropdown
                  id="settings-team-edit-role"
                  label={`${t("settings.editRoleFor")}  ${newMemberFirstName} ${newMemberLastName}`}
                  options={newMemberRoleTypeOptions}
                  onChange={teamRoleTypeHandler}
                  value={selectedNewMemberRoleType}
                  errormessage={validationErrorsUpdate.roleType}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </ModalContent>
        <ModalCTAs>
          <CTA id="settings-team-edit-cancel" size="lg" intent="secondary" onClick={() => closeEditTeamMember()}>
            {t("common.cancel")}
          </CTA>
          <CTA id="settings-team-invite" size="lg" intent="primary" onClick={editMemberRole}>
            {t("common.save")}
          </CTA>
        </ModalCTAs>
      </Modal>
    </>
  );
}
