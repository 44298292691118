import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyById } from "../../../../services/Company";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import Modal, { ModalCTAs, ModalContent } from "../Modal";
import CTA from "../../atoms/CTA";
import Text from "../../atoms/Text";

import styles from "./styles.module.css";
import { Order } from "types";

export interface OrderCardProps {
  order: Order;
  handleActiveOrder: (Order: any) => void;
  deleteOrder: (orderId: number) => void;
  orderTypeIndicator: "saleIndicator" | "purchaseIndicator";
}

export default function OrderCard(props: OrderCardProps) {
  const { t } = useTranslation();
  const { order, handleActiveOrder, deleteOrder } = props;
  const [itemLocation, setItemLocation] = useState(order?.orderAddress?.place);

  useEffect(() => {
    async function getFallbackLocation() {
      const company = await getCompanyById(order?.company?.id);

      const shippingAddress = company?.data?.companyAddress.find((x: any) => x.addressType === "SHIPPING");
      const visitingAddress = company?.data?.companyAddress.find((x: any) => x.addressType === "VISITING");
      const itemAddress = shippingAddress.place || visitingAddress.place;

      setItemLocation(itemAddress);
    }

    const orderAddress = order?.orderAddress?.place;

    if (!orderAddress) {
      getFallbackLocation();
    } else {
      setItemLocation(orderAddress);
    }
  }, [order]);

  const [deleteOrderConfirmationConfig, setDeleteOrderConfirmationConfig] = useState({
    isOpen: false,
    text: "",
  });

  function handleDetails() {
    handleActiveOrder(order);
  }

  function handleCancel() {
    setDeleteOrderConfirmationConfig({
      isOpen: true,
      text: t("commonOrders.cancelTextConfirmation"),
    });
  }

  function cancelOrder() {
    order.id && deleteOrder(order.id);
    closeCancelConfirmation();
  }

  function closeCancelConfirmation() {
    setDeleteOrderConfirmationConfig({ isOpen: false, text: "" });
  }

  const plasticType = order?.salesItem?.salesItem?.toLowerCase();

  return (
    <>
      <Modal
        open={deleteOrderConfirmationConfig.isOpen}
        onClose={() => closeCancelConfirmation()}
        headerText={t("common.confirm")}
      >
        <ModalContent>
          <Text>{deleteOrderConfirmationConfig.text}</Text>
        </ModalContent>
        <ModalCTAs>
          <CTA id="delete-order-cancel" intent="secondary" onClick={() => closeCancelConfirmation()}>
            {t("common.cancel")}
          </CTA>
          <CTA id="delete-order-confirm" intent="primary" onClick={() => cancelOrder()}>
            {t("common.accept")}
          </CTA>
        </ModalCTAs>
      </Modal>
      <section className={styles.activeOrderCardRoot}>
        <header className={styles.productName}>
          <SVGAsset name={`plastic-types/${plasticType}`} />
          <Text size="xs" display="title" strong={true}>
            {t("common.plastic." + plasticType)}
          </Text>
        </header>
        <Text size="lg" strong={true} tt="uppercase">
          {order?.company?.companyName ?? ""}
        </Text>
        <ul className={styles.productSpecs}>
          <li>
            <Text size="xs" strong={true} tt="uppercase">
              Origin
            </Text>
            <Text size="xs" tt="uppercase">
              {itemLocation}
            </Text>
          </li>
          <li>
            <Text size="xs" tt="uppercase" strong={true}>
              Weight
            </Text>
            <Text size="xs" tt="uppercase">
              {order?.amount ?? ""} {order?.unit?.unit ?? ""}
            </Text>
          </li>
        </ul>
        <div className={styles.ctas}>
          <CTA intent="secondary" onClick={handleCancel}>
            {t("commonOrders.cancel")}
          </CTA>
          <CTA intent="primary" onClick={handleDetails}>
            {t("common.details")}
          </CTA>
        </div>
      </section>
    </>
  );
}
