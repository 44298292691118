import Text from "../../components/_Reviver/atoms/Text";
import SVGAsset from "../../components/SVGAsset/SVGAsset";

export const buildUserOptions = (user, currentCompany, companies, t, isNavigationDisabled) => {
  if (!user) return [];

  let userOptions = [
    {
      type: "fixed-value",
      content: (
        <div className="user">
          <SVGAsset name="user-white" />
          <div className="name-company">
            {currentCompany && (
              <Text size="sm" strong={true} display="title" tt="uppercase">
                {currentCompany.companyName}
              </Text>
            )}
            <Text size="sm">
              {user.firstname} {user.lastname}
            </Text>
          </div>
        </div>
      ),
    },
  ];

  if (companies) {
    userOptions = userOptions.concat(
      [
        {
          type: "heading",
          value: t("common.header.companies"),
        },
      ],
      companies.companies.map((company) => {
        const isCurrent = company.companyId === currentCompany.companyId;
        return {
          value: company.companyId,
          content: (
            <div className="flex align gap10">
              <SVGAsset name="company-icon" className="circle" />
              <div>{company.companyName}</div>
            </div>
          ),
          disabled: isCurrent,
        };
      }),
      [
        {
          type: "separator",
        },
      ]
    );
  }

  if (!isNavigationDisabled) {
    userOptions = userOptions.concat([
      {
        value: "settings",
        content: (
          <div className="flex align gap10">
            <SVGAsset name="cogs" className="circle" />
            <div>{t("common.header.settings")}</div>
          </div>
        ),
      },
    ]);
  }

  userOptions = userOptions.concat([
    {
      value: "logout",
      content: (
        <div className="flex align gap10">
          <SVGAsset name="door-with-arrow" className="circle" />
          <div>{t("common.header.logout")}</div>
        </div>
      ),
    },
  ]);

  return userOptions;
};
