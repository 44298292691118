import React, { useState, useContext, useEffect } from "react";
import { ErrorDialogContext } from "../../contexts/ErrorDialogContext";
import Modal, { ModalCTAs, ModalContent } from "../../components/_Reviver/organisms/Modal";
import Text from "../../components/_Reviver/atoms/Text";
import MessageBar from "../../components/MessageBar/MessageBar";
import CTA from "../../components/_Reviver/atoms/CTA";
import Stack from "components/_Reviver/atoms/Stack";

export default function ErrorDialog() {
  const errorDialogContext = useContext(ErrorDialogContext);
  const { isErrorDialogOpen, showErrorDialog, errorDialogOptions } = errorDialogContext;
  const [open, setOpen] = useState(isErrorDialogOpen);

  useEffect(() => {
    setOpen(isErrorDialogOpen);
  }, [setOpen, isErrorDialogOpen]);

  function closeDialog() {
    showErrorDialog(false);
    if (errorDialogOptions.onClose) {
      errorDialogOptions.onClose();
    }
  }

  function getMessageBar(exception) {
    return <MessageBar type={MessageBar.type.Exception} message={exception.message} />;
  }

  return (
    <>
      <Modal open={open || false} onClose={errorDialogOptions.onClose || null} headerText="Error">
        <ModalContent>
          <Stack gap="10px" align="center">
            <Text>{errorDialogOptions.message}</Text>
            {!!errorDialogOptions.error && getMessageBar(errorDialogOptions.error)}
          </Stack>
        </ModalContent>
        <ModalCTAs>
          <CTA id="btn-close-dialog" onClick={closeDialog}>
            {"OK"}
          </CTA>
        </ModalCTAs>
      </Modal>
    </>
  );
}
