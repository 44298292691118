import { FC, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavItem from "../../atoms/NavItem";
import styles from "./styles.module.css";

export interface NavigationItem {
  url: string;
  translationsKey: string;
}

interface NavigationProps {
  items: NavigationItem[];
  variant?: "primary" | "secondary";
  taskCount?: number;
}

interface Props {
  taskCount?: number;
}

const adminNavItems: NavigationItem[] = [
  {
    url: "/verifyCustomer",
    translationsKey: "common.newApplications",
  },
  {
    url: "/statistics",
    translationsKey: "common.statistics",
  },
  {
    url: "/staleTransactions",
    translationsKey: "staleTransactions.staleTransactions",
  },
  {
    url: "/adminFeedback",
    translationsKey: "commonAdmin.adminFeedback",
  },
];

const mainNavItems: NavigationItem[] = [
  {
    url: "/dashboard",
    translationsKey: "dashboard.dashboard",
  },
  {
    url: "/trade",
    translationsKey: "trade.trade",
  },
  {
    url: "/task",
    translationsKey: "common.tasks",
  },
];

export const BaseNavigation: FC<PropsWithChildren<NavigationProps>> = ({ items, taskCount, variant }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const getItemText = (item: any) =>
    item.url.includes("task") ? t(item.translationsKey) + ` [${taskCount}]` : t(item.translationsKey);

  return (
    <nav className={`${styles.navRoot} ${variant !== "primary" && styles[variant as keyof typeof styles]}`}>
      {items.map((item, i) => (
        <NavItem href={item.url} text={getItemText(item)} isActive={location.pathname === item.url} key={i} />
      ))}
    </nav>
  );
};

export const Navigation: FC<Props> = (props) => <BaseNavigation items={mainNavItems} variant="primary" {...props} />;
export const AdminNavigation: FC<Props> = () => <BaseNavigation items={adminNavItems} variant="secondary" />;

export default Navigation;
