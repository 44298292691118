import { FC, PropsWithChildren } from "react";
import Text from "../Text";
import { UnstyledButton } from "@mantine/core";
import styles from "./styles.module.css";
import { OrderStatus, OrderTypeEnumKeys } from "types";

interface Props {
  status: OrderTypeEnumKeys | OrderStatus;
  onClick?: () => void;
}

const Tag: FC<PropsWithChildren<Props>> = ({ status, ...props }) => {
  if (!status) return null;
  const statusClass = status?.toLowerCase() as keyof typeof styles;

  return (
    <UnstyledButton className={`${styles.tagRoot} ${status ? styles[statusClass] : ""}`}>
      <Text size="xs" display="title" tt="uppercase" {...props} />
    </UnstyledButton>
  );
};

export default Tag;
