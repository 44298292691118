import { Dispatch, SetStateAction } from "react";

import FileUpload, {
  // convertNestedObjectToArray,
  convertToString,
} from "components/_Reviver/organisms/FileUpload";

interface Props {
  onChange: (change: any) => void;
  values: any;
  fieldValidationErrors: any;
  setFieldValidationErrors: Dispatch<SetStateAction<{}>>;
}

export default function OrderDetails({ onChange, ...props }: Props) {
  const { values, fieldValidationErrors } = props;

  const onChangeFiles = (files: any[]) => {
    return onChange({
      images: convertToString(files),
      // images: convertNestedObjectToArray(files),
      files,
    });
  };

  return (
    <FileUpload
      label={values.salesItem.value}
      updateFilesCb={onChangeFiles}
      error={fieldValidationErrors.images}
      files={values?.files}
      multiple={true}
    />
  );
}
