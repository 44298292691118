import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { getCreateOrderValues, getValidConditions, getValidSizes, getBoxtypeValues } from "../../../../services/Orders";
import {
  postSaveOrderTemplate,
  getCompanyOrderTemplates,
  deleteOrderTemplate,
  getCompanyNetworkFull,
} from "../../../../services/Company";
import { orderTemplateSchema } from "./validation/SettingsValidationSchemas";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";

import createOrderTemplateStructure from "../../../../structures/CreateOrderTemplateStructure";
import UserContext from "../../../../contexts/UserContext";
import Grid from "../../../Grid/Grid";
import Dropdown from "../../../Dropdown/Dropdown";
import Textfield from "../../../TextField/TextField";
import DropdownMulti from "../../../DropdownMulti/DropdownMulti";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import Space from "../../atoms/Space";
import {
  BoxType,
  Company,
  Condition,
  DropdownOption,
  MessageBarOptions,
  OrderValue,
  Size,
  Template,
  TemplateOption,
  ValidationErrors,
} from "types";
import MessageBar from "components/MessageBar/MessageBar";

export default function TemplateSettings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [orderTemplate, setOrderTemplate] = useState(createOrderTemplateStructure());
  //dropdowns
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption | null>(null);
  const [salesItemsOptions, setSalesItemsOptions] = useState<DropdownOption[]>([]);
  const [selectedSalesItem, setSelectedSalesItem] = useState<DropdownOption | null>(null);
  const [treatmentOptions, setTreatmentOptions] = useState<DropdownOption[]>([]);
  const [selectedTreatment, setSelectedTreatment] = useState<DropdownOption | null>(null);
  const [toolOptions, setToolOptions] = useState<DropdownOption[]>([]);
  const [selectedTool, setSelectedTool] = useState<DropdownOption | null>(null);
  const [conditionOptions, setConditionsOptions] = useState<DropdownOption[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<DropdownOption | null>(null);
  const [sizeOptions, setSizeOptions] = useState<DropdownOption[]>([]);
  const [selectedSize, setSelectedSize] = useState<DropdownOption | null>(null);
  const [boxTypeOptions, setBoxTypeOptions] = useState<DropdownOption[]>([]);
  const [selectedBoxType, setSelectedBoxType] = useState<DropdownOption | null>(null);
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOption[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<DropdownOption[]>([]);
  //validation
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [messageBarOptions, setMessageBarOptions] = useState<MessageBarOptions | null>(null);

  useEffect(() => {
    async function asyncLoadTemplates() {
      const result = await getCompanyOrderTemplates(currentCompany.companyId);

      const _companyTemplates = result.data.orderTemplates.map((template: Template) => ({
        value: template.templateId,
        label: template.templateName,
        template: template,
      }));
      setTemplateOptions(_companyTemplates);
    }

    asyncLoadTemplates();
  }, [currentCompany]);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetDropdownValues() {
        const values = await getCreateOrderValues();
        return values.data;
      }
      async function asyncGetBoxTypeValues() {
        const values = await getBoxtypeValues();
        return values.data;
      }
      const dropDownValues = await asyncGetDropdownValues();
      const boxTypeValues = await asyncGetBoxTypeValues();

      //  Sales-items options
      const _salesItemOptions = dropDownValues.salesItems.map((salesItem: OrderValue) => ({
        value: salesItem.id,
        label: t("common.plastic." + salesItem.value),
      }));
      setSalesItemsOptions(_salesItemOptions);

      //  Treatment options
      const _treatmentOptions = dropDownValues.treatments.map((treatment: OrderValue) => ({
        value: treatment.id,
        label: t("common.treatments." + treatment.value),
      }));
      setTreatmentOptions(_treatmentOptions);

      //  Tool options
      const _toolOptions = dropDownValues.gears.map((gear: OrderValue) => ({
        value: gear.id,
        label: t("common.tools." + gear.value),
      }));
      setToolOptions(_toolOptions);

      //  BoxType options
      const _boxTypeOptions = boxTypeValues.boxtypes.map((boxType: BoxType) => ({
        value: boxType.id,
        label: t("common.boxtypes." + boxType.name),
      }));
      setBoxTypeOptions(_boxTypeOptions);
    }

    // Companies
    async function asyncGetAllCompanies() {
      const availableCompanies = await getCompanyNetworkFull(currentCompany.companyId);

      const _availableCompanies = availableCompanies.data.companies.map((company: Company) => ({
        value: company.id,
        label: company.companyName,
      }));
      setCompaniesOptions(_availableCompanies);
    }

    loadOptions();
    asyncGetAllCompanies();
  }, [t, currentCompany.companyId]);

  async function asyncLoadValidConditions(salesItemId: number) {
    async function asyncGetValidConditions() {
      const conditions = await getValidConditions(salesItemId);
      return conditions.data;
    }

    const conditions = await asyncGetValidConditions();

    const _conditionOptions = conditions.map((condition: Condition) => ({
      value: condition.id,
      label: t("common.conditions." + condition.condition),
    }));

    setConditionsOptions(_conditionOptions);

    return _conditionOptions;
  }

  async function asyncLoadValidSizes(salesItemId: number, conditionId: number) {
    async function asyncGetValidSizes() {
      const sizes = await getValidSizes(salesItemId, conditionId);
      return sizes.data;
    }

    const sizes = await asyncGetValidSizes();

    const _sizeOptions = sizes.map((size: Size) => ({
      value: size.id,
      label: size.size,
    }));

    setSizeOptions(_sizeOptions);

    return _sizeOptions;
  }

  function resetDropdowns() {
    setSelectedTemplate(null);
    setSelectedSalesItem(null);
    setSelectedCondition(null);
    setSelectedSize(null);
    setSelectedTool(null);
    setSelectedTreatment(null);
    setSelectedBoxType(null);
    setSelectedCompanies([]);
  }

  async function onChangeSelectedTemplate(e: any) {
    setSelectedTemplate(e);
    setOrderTemplate(e.template);

    async function setSalesItemConditionSize(selectedTemplate: Template) {
      const _selectedSalesItem = salesItemsOptions.find(
        (salesItem) => salesItem.value === selectedTemplate.salesItemId
      );
      setSelectedSalesItem(_selectedSalesItem || null);

      const _conditionOptions = _selectedSalesItem && (await asyncLoadValidConditions(_selectedSalesItem?.value));
      const _selectedCondition = _conditionOptions.find(
        (condition: DropdownOption) => condition.value === selectedTemplate.conditionId
      );
      setSelectedCondition(_selectedCondition);

      const _sizeOptions =
        _selectedSalesItem && (await asyncLoadValidSizes(_selectedSalesItem?.value, _selectedCondition.value));
      const _selectedSize = _sizeOptions.find((size: DropdownOption) => size.value === selectedTemplate.sizeId);
      setSelectedSize(_selectedSize);
    }

    await setSalesItemConditionSize(e.template);
    const _selectedTool = toolOptions.find((tool) => tool.value === e.template.gearId);
    setSelectedTool(_selectedTool || null);
    const _selectedTreatment = treatmentOptions.find((treatment) => treatment.value === e.template.treatmentId);
    setSelectedTreatment(_selectedTreatment || null);
    const _selectedBoxType = boxTypeOptions.find((boxtype) => boxtype.value === e.template.boxTypeId);
    setSelectedBoxType(_selectedBoxType || null);
    const _selectedCompanies = companiesOptions.filter((company) =>
      e.template.orderTemplateNetwork.some((template: Template) => template.companyId === company.value)
    );
    setSelectedCompanies(_selectedCompanies);
  }

  function newTemplateHandler() {
    setOrderTemplate(createOrderTemplateStructure());
    resetDropdowns();
  }

  async function saveHandler() {
    const validationResult = validateFieldsHelper(orderTemplateSchema(t), orderTemplate);
    setValidationErrors(validationResult as any);

    if (Object.keys(validationResult || {}).length) return;

    orderTemplate.companyId = currentCompany.companyId;
    await postSaveOrderTemplate(orderTemplate);

    setMessageBarOptions({ message: t("settings.addTemplateSuccess"), type: "Positive" });
  }

  async function deleteHandler() {
    await deleteOrderTemplate(selectedTemplate?.template.templateId);

    setOrderTemplate(createOrderTemplateStructure());
    resetDropdowns();
    setMessageBarOptions({ message: t("settings.deleteTemplateSuccess"), type: "Warning" });
  }

  function onChangeOrderTemplate(change: any) {
    setOrderTemplate({
      ...orderTemplate,
      ...change,
    });
  }

  function resetConditionAndSize() {
    setSelectedCondition(null);
    onChangeOrderTemplate({ condition: 0 });
    resetSize();
  }
  function resetSize() {
    setSelectedSize(null);
    onChangeOrderTemplate({ size: 0 });
  }

  async function onChangeSalesItem(e: any) {
    setSelectedSalesItem(e);
    resetConditionAndSize();
    await asyncLoadValidConditions(e.value);
    onChangeOrderTemplate({ salesItemId: e.value });
  }
  async function onChangeCondition(e: any) {
    setSelectedCondition(e);
    resetSize();
    selectedSalesItem && (await asyncLoadValidSizes(selectedSalesItem?.value, e.value));
    onChangeOrderTemplate({ conditionId: e.value });
  }
  function onChangeSize(e: any) {
    setSelectedSize(e);
    onChangeOrderTemplate({ sizeId: e.value });
  }
  function onChangeTool(e: any) {
    setSelectedTool(e);
    onChangeOrderTemplate({ gearId: e.value });
  }
  function onChangeTreatment(e: any) {
    setSelectedTreatment(e);
    onChangeOrderTemplate({ treatmentId: e.value });
  }
  function onChangeBoxType(e: any) {
    setSelectedBoxType(e);
    onChangeOrderTemplate({ boxTypeId: e.value });
  }
  function onChangeTargetCompanies(e: any) {
    setSelectedCompanies(e);
    const companies = e.map((company: DropdownOption) => {
      return { companyId: company.value };
    });
    onChangeOrderTemplate({ orderTemplateNetwork: companies });
  }

  return (
    <>
      <Heading order={2} styleOrder={11} tt="uppercase">
        {t("settings.templates")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="title" tt="uppercase">
                {t("settings.chooseExistingTemplate")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={10}>
              <Dropdown
                id="template"
                label="Eksisterende template"
                options={templateOptions}
                value={selectedTemplate}
                onChange={(e) => onChangeSelectedTemplate(e)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Space height="24px" />
              <CTA id="new-template" intent="secondary" onClick={() => newTemplateHandler()}>
                {t("settings.newTemplate")}
              </CTA>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="title" tt="uppercase">
                {t("settings.newTemplate")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={10}>
              <Textfield
                id="template-name"
                placeholder="Template name"
                label={t("settings.templateName")}
                value={orderTemplate.templateName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOrderTemplate({ templateName: e.target.value })}
                errormessage={validationErrors.templateName}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="sales-item"
                label={t("common.type")}
                options={salesItemsOptions}
                value={selectedSalesItem}
                onChange={(e: ChangeEvent) => onChangeSalesItem(e)}
                errormessage={validationErrors.salesItemId}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="condition"
                label={t("commonOrders.conditions")}
                options={conditionOptions}
                value={selectedCondition}
                onChange={(e: ChangeEvent) => onChangeCondition(e)}
                errormessage={validationErrors.conditionId}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Dropdown
                id="size"
                label={t("commonOrders.sizes")}
                options={sizeOptions}
                value={selectedSize}
                onChange={(e: ChangeEvent) => onChangeSize(e)}
                errormessage={validationErrors.sizeId}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="tool"
                label={t("commonOrders.gears")}
                options={toolOptions}
                value={selectedTool}
                onChange={(e: ChangeEvent) => onChangeTool(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Dropdown
                id="treatment"
                label={t("commonOrders.treatments")}
                options={treatmentOptions}
                value={selectedTreatment}
                onChange={(e) => onChangeTreatment(e)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={6}>
              <Dropdown
                id="box-type"
                label={t("common.boxtype")}
                options={boxTypeOptions}
                value={selectedBoxType}
                onChange={(e) => onChangeBoxType(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DropdownMulti
                id="network"
                label={t("common.companyToTradeWith")}
                closeMenuOnSelect={false}
                menuPlacement="top"
                options={companiesOptions}
                value={selectedCompanies}
                onChange={(e) => onChangeTargetCompanies(e)}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              {messageBarOptions && <MessageBar type={messageBarOptions.type} message={messageBarOptions.message} />}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormCTAs>
        <CTA size="lg" id="delete" intent="secondary" disabled={!selectedTemplate} onClick={() => deleteHandler()}>
          {t("settings.delete")}
        </CTA>
        <CTA size="lg" id="save" intent="primary" onClick={() => saveHandler()}>
          {t("common.save")}
        </CTA>
      </SettingsFormCTAs>
    </>
  );
}
