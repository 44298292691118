import React, { useState, useEffect, useContext, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyNetwork, postSaveCompanyNetwork, getAllCompanies } from "../../../../services/Company";

import UserContext from "../../../../contexts/UserContext";
import DropdownMulti from "../../../../components/DropdownMulti/DropdownMulti";
import Grid from "../../../Grid/Grid";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import LockIcon from "../../atoms/Icons/Lock";
import NetworkIcon from "../../atoms/Icons/Network";
import { Company, CompanyOption, NetworkCompany } from "types";

export default function NetworkSettings() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;
  const [companyOptions, setCompanyOptions] = useState<CompanyOption[]>([]);
  const [existingNetwork, setExistingNetwork] = useState<CompanyOption[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<CompanyOption[]>([]);

  useEffect(() => {
    async function loadOptions() {
      async function asyncGetAllCompanies() {
        const allCompanies = await getAllCompanies();
        return allCompanies.data;
      }

      const allCompanies = await asyncGetAllCompanies();

      const _companyOptions = (allCompanies.companies as Company[]).map((company) => ({
        value: company.id,
        label: company.companyName,
        vatNo: company.vatNumber,
      }));
      setCompanyOptions(_companyOptions as CompanyOption[]);
    }

    loadOptions();
  }, []);

  useEffect(() => {
    // Prefill selected companies if company has an existing network
    async function preFillSelectedCompanies() {
      async function asyncGetCompanyNetwork() {
        const companies = await getCompanyNetwork(currentCompany.companyId);
        return companies.data;
      }

      const existingNetwork: NetworkCompany[] = await asyncGetCompanyNetwork();

      const _selectedCompanies = existingNetwork.map((company) => ({
        value: company.companyId,
        label: company.companyName,
        vatNo: company.vatno,
      }));

      setExistingNetwork(_selectedCompanies);
      setSelectedCompanies(_selectedCompanies);
    }

    preFillSelectedCompanies();
  }, [currentCompany.companyId]);

  async function saveHandler() {
    setExistingNetwork(selectedCompanies);
    // Map dropdown propNames to api propNames
    const _selectedCompanies: NetworkCompany[] = selectedCompanies.map((company: CompanyOption) => ({
      companyId: company.value,
      companyName: company.label,
      vatno: company.vatNo,
    }));

    await postSaveCompanyNetwork(currentCompany.companyId, _selectedCompanies);
  }

  return (
    <>
      <Heading order={2} styleOrder={11} tt="uppercase">
        {t("settings.network")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="title" tt="uppercase">
                {t("settings.chooseNetwork")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <DropdownMulti
                id="companies"
                label={t("settings.networks")}
                value={selectedCompanies}
                options={companyOptions}
                onChange={(e) => setSelectedCompanies(e)}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormCTAs>
        <CTA id="save" size="lg" onClick={() => saveHandler()}>
          {t("common.save")}
        </CTA>
      </SettingsFormCTAs>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="title" tt="uppercase">
                {t("settings.networkList")}
              </Text>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={12}>
              <table className="lightblue">
                <tbody>
                  {existingNetwork?.map((company: any, index: number) => (
                    <tr key={index}>
                      <td>{company.label}</td>
                      <td>
                        <LockedNetwork />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
    </>
  );
}

const LockedNetwork = () => {
  const style: CSSProperties = {
    position: "absolute",
    right: 0,
    top: 0,
    color: "var(--color-grey-75)",
    height: "100%",
    display: "flex",
  };
  const iconStyle: CSSProperties = {
    width: "36px",
    height: "100%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "var(--border-secondary-normal)",
  };
  return (
    <div style={style}>
      <span style={iconStyle}>
        <LockIcon width="24" height="24" />
      </span>
      <span style={iconStyle}>
        <NetworkIcon width="24" height="24" />
      </span>
    </div>
  );
};
