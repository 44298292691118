import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { validateFieldsHelper } from "../../../utils/ValidationHelper";
import { registerCompanyStep3SchemaAccount } from "../RegisterCompanyValidationSchema";
import { registerCompanyStep3SchemaBank } from "../RegisterCompanyValidationSchema";

import HeadingWithInfoText from "../../../components/HeadingWithInfoText/HeadingWithInfoText";
import Grid from "../../../components/Grid/Grid";
import TextField from "../../../components/TextField/TextField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Spinner from "../../../components/Spinner/Spinner";
import CTA from "components/_Reviver/atoms/CTA";

export default function Step3(props) {
  const {
    draft,
    onChange,
    clickBackHandler,
    clickNextHandler,
    languageOptions,
    timeZoneOptions,
    transportOptions,
    companyTypesOptions,
    membershipTypesOptions,
  } = props;

  const DEFAULT_LANGUAGE = languageOptions.find((option) => option.value === 1);
  const DEFAULT_COMPANY_TYPE = companyTypesOptions.find((option) => option.value === "TRADER");
  const DEFAULT_TRANSPORT = transportOptions.find((option) => option.value === false);
  const DEFAULT_MEMBERSHIP = membershipTypesOptions.find((option) => option.value === "STANDARD");

  const [selectedBankAccountName, setSelectedBankAccountName] = useState(
    get(draft, "partialCompany.bankAccount[0].name", null)
  );
  const [selectedBankAccountNumber, setSelectedBankAccountNumber] = useState(
    get(draft, "partialCompany.bankAccount[0].accountNo", null)
  );
  const [selectedBankAccountIban, setSelectedBankIban] = useState(
    get(draft, "partialCompany.bankAccount[0].iban", null)
  );

  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [selectedCompanyType, setSelectedCompanyType] = useState(null);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const [isSavingDraft, setSavingDraft] = useState(false);
  const [selectedMembershipType, setSelectedMembershipType] = useState(null);
  const [validationErrorsBank, setValidationErrorsBank] = useState([]);
  const [validationErrorsAccount, setValidationErrorsAccount] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const _selectedLanguage = languageOptions.find(
      (option) => option.value === get(draft, "partialCompany.languageId")
    );
    if (_selectedLanguage) {
      setSelectedLanguage(_selectedLanguage);
    } else {
      setSelectedLanguage(DEFAULT_LANGUAGE);
      onChange({
        languageId: DEFAULT_LANGUAGE.value,
      });
    }

    const _selectedTimeZone = timeZoneOptions.find((option) => option.value === get(draft, "partialCompany.timeZone"));
    setSelectedTimeZone(_selectedTimeZone);

    const _selectedCompanyType = companyTypesOptions.find(
      (option) => option.value === get(draft, "partialCompany.companyType")
    );
    if (_selectedCompanyType) {
      setSelectedCompanyType(_selectedCompanyType);
    } else {
      setSelectedCompanyType(DEFAULT_COMPANY_TYPE);
      onChange({
        companyType: DEFAULT_COMPANY_TYPE.value,
      });
    }

    const _selectedTransport = transportOptions.find(
      (option) => option.value === get(draft, "partialCompany.companyProvidesTransport")
    );
    if (_selectedTransport) {
      setSelectedTransport(_selectedTransport);
    } else {
      setSelectedTransport(DEFAULT_TRANSPORT);
      onChange({
        companyProvidesTransport: DEFAULT_TRANSPORT.value,
      });
    }

    const _selectedMembershipType = membershipTypesOptions.find(
      (option) => option.value === get(draft, "partialCompany.membershipType")
    );
    if (_selectedMembershipType) {
      setSelectedMembershipType(_selectedMembershipType);
    } else {
      setSelectedMembershipType(DEFAULT_MEMBERSHIP);
      onChange({
        membershipType: DEFAULT_MEMBERSHIP.value,
      });
    }
  }, [
    draft,
    companyTypesOptions,
    languageOptions,
    transportOptions,
    timeZoneOptions,
    membershipTypesOptions,
    DEFAULT_TRANSPORT,
    DEFAULT_COMPANY_TYPE,
    DEFAULT_LANGUAGE,
    DEFAULT_MEMBERSHIP,
    onChange,
  ]);

  function onChangeBankAccountName(e) {
    setSelectedBankAccountName(e.target.value);
    onChange({
      bankAccount: [
        {
          name: e.target.value,
          accountNo: selectedBankAccountNumber,
          iban: selectedBankAccountIban,
        },
      ],
    });
  }

  function onChangeBankAccountNumber(e) {
    setSelectedBankAccountNumber(e.target.value);
    onChange({
      bankAccount: [
        {
          name: selectedBankAccountName,
          accountNo: e.target.value,
          iban: selectedBankAccountIban,
        },
      ],
    });
  }

  function onChangeBankIban(e) {
    setSelectedBankIban(e.target.value);
    onChange({
      bankAccount: [
        {
          name: selectedBankAccountName,
          accountNo: selectedBankAccountNumber,
          iban: e.target.value,
        },
      ],
    });
  }

  function onChangeLanguage(e) {
    setSelectedLanguage(e);
    console.log(e);
    onChange({
      languageId: e.value,
    });
  }

  function onChangeTimeZone(e) {
    setSelectedTimeZone(e);
    const timeZone = e.value;
    onChange({
      timeZone: timeZone,
    });
  }

  function onChangeTransport(e) {
    setSelectedTransport(e);
    onChange({
      companyProvidesTransport: e.value,
    });
  }

  function onChangeCompanyType(e) {
    setSelectedCompanyType(e);
    const companyType = e.value;
    onChange({
      companyType,
    });
  }

  function onChangeMembershipType(e) {
    setSelectedMembershipType(e);
    const memberShipType = e.value;
    onChange({
      membershipType: memberShipType,
    });
  }

  async function nextHandler() {
    const inputCheckerBank = validateFieldsHelper(registerCompanyStep3SchemaBank(t), {
      name: selectedBankAccountName,
      accountNo: selectedBankAccountNumber,
      iban: selectedBankAccountIban,
    });
    const inputCheckerAccount = validateFieldsHelper(registerCompanyStep3SchemaAccount(t), {
      languageId: selectedLanguage.value,
      timeZone: selectedTimeZone,
      companyProvidesTransport: selectedTransport.value,
      companyType: selectedCompanyType,
      membershipType: selectedMembershipType,
    });

    setValidationErrorsBank(inputCheckerBank);
    setValidationErrorsAccount(inputCheckerAccount);
    console.log(inputCheckerAccount);
    console.log(inputCheckerBank);
    if (Object.keys(inputCheckerBank).length === 0 && Object.keys(inputCheckerAccount).length === 0) {
      setSavingDraft(true);
      await clickNextHandler();
    } else {
      console.log("NOE GIKK FEIL BANK");
    }
  }

  async function backHandler() {
    setSavingDraft(true);
    await clickBackHandler();
  }

  return (
    <>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step3.infoHeader")} showInfo={true} infoText={t("step3.infoText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-row">
              <TextField
                id="txt-accountName"
                label={t("step3.bankName")}
                onChange={(e) => onChangeBankAccountName(e)}
                value={selectedBankAccountName}
                errormessage={validationErrorsBank.name}
              />
            </div>
            <div className="content-row">
              <TextField
                id="txt-bankaccount"
                label={t("step3.bankNumber")}
                value={selectedBankAccountNumber}
                onChange={(e) => onChangeBankAccountNumber(e)}
                errormessage={validationErrorsBank.accountNo}
              />
              <TextField
                id="txt-iban"
                label="IBAN"
                value={selectedBankAccountIban}
                onChange={(e) => onChangeBankIban(e)}
                errormessage={validationErrorsBank.iban}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step3.language")} showInfo={true} infoText={t("step3.languageText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-container-dropdown">
              <Dropdown
                id="dd-language"
                label={t("step3.language")}
                options={languageOptions}
                onChange={onChangeLanguage}
                value={selectedLanguage}
                errormessage={validationErrorsAccount.languageId}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step3.time")} showInfo={true} infoText={t("step3.timeText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-container-dropdown">
              <Dropdown
                id="dd-timezone"
                label={t("step3.time")}
                options={timeZoneOptions}
                onChange={onChangeTimeZone}
                value={selectedTimeZone}
                errormessage={validationErrorsAccount.timeZone}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText header={t("step3.transport")} showInfo={true} infoText={t("step3.transportText")} />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-container-dropdown">
              <Dropdown
                id="dd-transport"
                label={t("step3.transportLabel")}
                options={transportOptions}
                onChange={onChangeTransport}
                value={selectedTransport}
                errormessage={validationErrorsAccount.companyProvidesTransport}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText
              header={t("step3.companyType")}
              showInfo={false}
              infoText={t("step3.companyTypeText")}
            />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-container-dropdown">
              <Dropdown
                id="dd-companytype"
                label={t("step3.companyType")}
                options={companyTypesOptions}
                onChange={onChangeCompanyType}
                value={selectedCompanyType}
                errormessage={validationErrorsAccount.companyType}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
      <Grid className="wizard-container-border-top nogap">
        <Grid.Row>
          <Grid.Col span={4} className="wizard-container-info-area">
            <HeadingWithInfoText
              header={t("step3.membershipType")}
              showInfo={false}
              infoText={t("step3.membershipTypeText")}
            />
          </Grid.Col>
          <Grid.Col span={8} className="content-container">
            <div className="content-container-dropdown">
              <Dropdown
                id="dd-membershiptype"
                label={t("step3.membershipType")}
                options={membershipTypesOptions}
                onChange={onChangeMembershipType}
                value={selectedMembershipType}
                errormessage={validationErrorsAccount.membershipType}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col push={5} span={8} className="action-buttons">
            <Spinner loading={isSavingDraft} inline={true} />
            <CTA id="wizard-step3-previous" intent="secondary" onClick={backHandler}>
              {t("registerCompany.back")}
            </CTA>
            <CTA id="wizard-step3-next" onClick={nextHandler}>
              {t("registerCompany.next")}
            </CTA>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}
