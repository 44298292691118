import { FC } from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import CTA from "../../atoms/CTA";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import styles from "./styles.module.css";

interface Props {
  onClick: () => void;
}

const EmptySearchContent: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.emptySearchContentRoot}>
      <Heading order={2} styleOrder={5}>
        {t("trade.searchNoHits")}
      </Heading>
      <SVGAsset name="arrow-up-down" />
      <Text>{t("trade.newOrderQuestion")}</Text>
      <CTA onClick={onClick}>{t("commonOrders.newOrder")}</CTA>
    </div>
  );
};

export default EmptySearchContent;
