import { FC, PropsWithChildren, SyntheticEvent } from "react";
import { Button, ButtonProps as MantineButtonProps } from "@mantine/core";
import Text, { TextSize } from "../Text";
import styles from "./styles.module.css";

export interface CTAProps extends MantineButtonProps {
  id?: string;
  size?: "sm" | "lg";
  intent?: "primary" | "secondary" | "tertiary" | "affirm" | "negate";
  onClick: (event: SyntheticEvent) => void;
  fullWidth?: boolean;
  type?: "button" | "submit" | "reset";
}

const CTA: FC<PropsWithChildren<CTAProps>> = ({ size = "sm", intent = "primary", fullWidth, ...props }) => {
  return (
    <Button
      vars={() => ({
        root: {
          "--button-height": `var(--button-${size}-height)`,
          "--button-padding-x": `var(--button-${size}-padding-x)`,
        },
      })}
      classNames={{
        root: `${styles.ctaRoot} ${fullWidth ? styles.ctaRootFullWidth : ""} ${styles[intent]}`,
        inner: styles.inner,
      }}
      tt="uppercase"
      {...props}
    >
      <Text span={true} size={size as TextSize} display="title">
        {props.children}
      </Text>
    </Button>
  );
};

export default CTA;
