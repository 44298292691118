import React, { FC, PropsWithChildren } from "react";
import PropTypes from "prop-types";
import SVGAsset from "../../../../components/SVGAsset/SVGAsset";
import Heading from "../../atoms/Heading";

import styles from "./styles.module.css";

interface Props {
  open?: boolean;
  onClose?: () => void;
  headerText?: string;
  svgAsset?: string;
}

const OrderModal: FC<PropsWithChildren<Props>> = (props) => {
  const { open, onClose, headerText, svgAsset } = props;

  function closeDialog() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      {!!open && (
        <div className={styles.orderModalRoot}>
          <div className={styles.orderModalInner}>
            <div className={styles.orderModalHeader}>
              <div className={styles.orderModalHeading}>
                <SVGAsset name={svgAsset} />
                <Heading order={2} styleOrder={7} tt="uppercase">
                  {headerText}
                </Heading>
              </div>
              <div className={styles.closeDialog} onClick={() => closeDialog()}>
                <SVGAsset className="close-x clickable" name="close-white"></SVGAsset>
              </div>
            </div>
            <div className={styles.modalBody}>{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export const OrderModalContent: FC<PropsWithChildren> = (props) => (
  <div className={styles.orderModalContent} {...props} />
);

export default OrderModal;

OrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  headerText: PropTypes.string,
  svgAsset: PropTypes.string,
};
