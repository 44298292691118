import OrderTypeEnum from "../constants/enums/OrderTypeEnum";
import { OrderTypeEnumValues } from "types";

export function getOrderTypeIndicator(orderTypeId: OrderTypeEnumValues) {
  if (orderTypeId === OrderTypeEnum.SELL) return "saleIndicator";
  return "purchaseIndicator";
}

export function getOrderTypeIndicatorLarge(orderTypeId: OrderTypeEnumValues) {
  if (orderTypeId === OrderTypeEnum.SELL) return "salesIndicatorLarge";
  return "purchaseIndicatorLarge";
}

export function getOrderTypeIndicatorWhite(orderTypeId: OrderTypeEnumValues) {
  if (orderTypeId === OrderTypeEnum.SELL) return "saleIndicator-white";
  return "purchaseIndicator-white";
}

export function saleOrPurchase(t: any, orderTypeId: OrderTypeEnumValues) {
  if (orderTypeId === OrderTypeEnum.SELL) {
    return {
      buttonType: "Tertiary",
      buttonText: t("commonOrders.purchase"), // user can click buy on sales orders
      svgAsset: "saleIndicator", // user should see it as a sales order,
      tabHeaderText: t("common.salesOrder"),
      tabSvgAsset: "salesIndicatorLarge",
      tabActionText: t("commonOrders.purchasenowheader"),
      tabActionSvgAsset: "purchaseIndicatorLarge",
      svgAssetWhite: "saleIndicator-white",
    };
  } else if (orderTypeId === OrderTypeEnum.AUCTION) {
    return {
      svgAssetWhite: "auction-white",
      tabHeaderText: t("commonOrders.auctionOrder"),
      tabSvgAsset: "auction-blue",
      buttonType: "Autobid",
      buttonText: t("commonOrders.setAutobid"),
      svgAsset: "auction-small-blue",
      tabActionSvgAsset: "auction-blue",
      tabActionText: "Autobud",
    };
  } else {
    return {
      buttonType: "Quaternary",
      buttonText: t("commonOrders.sell"), // user can click sell on a purchase order
      svgAsset: "purchaseIndicator", // user should see it as a purchase order
      tabHeaderText: t("common.purchaseOrder"),
      tabSvgAsset: "purchaseIndicatorLarge",
      tabActionText: "Sell to this order",
      tabActionSvgAsset: "salesIndicatorLarge",
      svgAssetWhite: "purchaseIndicator-white",
    };
  }
}
