import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getOrdersForCompany } from "../../../../services/Orders";
import { getAllCompanies } from "../../../../services/Company";
import { postUserMadeAdvertisement } from "../../../../services/Messaging";
import { validateFieldsHelper } from "../../../../utils/ValidationHelper";

import Joi from "joi";
import UserContext from "../../../../contexts/UserContext";
import Grid from "../../../Grid/Grid";
import CheckBox from "../../../Checkbox/Checkbox";
import DropdownMulti from "../../../DropdownMulti/DropdownMulti";
import SVGAsset from "../../../SVGAsset/SVGAsset";
import OrderTypeEnum from "../../../../constants/enums/OrderTypeEnum";
import Spinner from "../../../Spinner/Spinner";
import TextfieldMulti from "../../../TextfieldMulti/TextfieldMulti";
import MessageBar from "../../../MessageBar/MessageBar";

import { SettingsFormContent, SettingsFormCTAs } from ".";
import Heading from "../../atoms/Heading";
import Text from "../../atoms/Text";
import Group from "../../atoms/Group";
import Divider from "../../atoms/Divider";
import StepCount from "../../atoms/StepCount";
import Space from "../../atoms/Space";
import CTA from "../../atoms/CTA";
import Stack from "../../atoms/Stack";
import { Company, NewsletterCompanyOption, CompanyOrders, Order, OrderTypeEnumValues, ValidationErrors } from "types";

export default function UserAdvertising() {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { currentCompany } = userContext;

  const [isLoadingOrder, setIsLoadingOrders] = useState<boolean>(false);
  const [companyOrders, setCompanyOrders] = useState<CompanyOrders | undefined>(undefined);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [companiesOptions, setCompaniesOptions] = useState<NewsletterCompanyOption[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<NewsletterCompanyOption[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<any[]>([]);
  const [currentEmailText, setCurrentEmailText] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [advertisementStatusMessage, setAdvertisementStatusMessage] = useState<string>("");
  const [advertisementStatusType, setAdvertisementStatusType] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedOrders([]);
    async function asyncGetOrders() {
      setIsLoadingOrders(true);
      const orders = await getOrdersForCompany(currentCompany.companyId, false, 1);
      setCompanyOrders(orders.data);
      setIsLoadingOrders(false);
    }

    asyncGetOrders();
  }, [currentCompany.companyId]);

  useEffect(() => {
    async function asyncGetCompanies() {
      const availableCompanies = await getAllCompanies();
      const _companies = availableCompanies.data.companies.map((company: Company) => ({
        value: company.id,
        label: company.companyName,
        tradingEmail: company.tradingemail,
        languageId: company.language.id,
      }));
      setCompaniesOptions(_companies);
    }

    asyncGetCompanies();
  }, []);

  function onClickCompanyOrder(selectedOrder: Order, e: any) {
    if (!selectedOrders.includes(selectedOrder)) {
      setSelectedOrders([...selectedOrders, selectedOrder]);
    } else {
      setSelectedOrders(selectedOrders.filter((order) => order.id !== selectedOrder.id));
    }

    e.preventDefault();
    e.stopPropagation();
  }

  const validationSchema = Joi.object({
    currentEmailText: Joi.string()
      .email({
        tlds: {
          allow: false,
        },
      })
      .messages({
        "*": t("validation.invalidEmail"),
      }),
  });

  const resetAdvertisementStatus = () => {
    setTimeout(() => {
      setAdvertisementStatusMessage("");
      setAdvertisementStatusType("");
    }, 5000);
  };

  async function sendAdvertisementHandler() {
    setIsLoading(true);
    const companyRecipients = selectedCompanies.map((company: NewsletterCompanyOption) => {
      return {
        email: company.tradingEmail,
        languageId: company.languageId,
        companyId: company.value,
      };
    });

    const emailRecipients = selectedEmails.map((email) => {
      return { email: email.label };
    });

    const finalRecipients: any[] = companyRecipients.concat(emailRecipients as any[]);
    const orderIds = selectedOrders.map((company) => company.id);

    const sendAdvertisement = await postUserMadeAdvertisement(finalRecipients, orderIds, currentCompany.companyName);

    if (sendAdvertisement.status === 200) {
      setAdvertisementStatusMessage("Success");
      setAdvertisementStatusType(MessageBar.type.Positive);
      resetAdvertisementStatus();
    } else {
      setAdvertisementStatusMessage("Failed");
      setAdvertisementStatusType(MessageBar.type.Negative);

      resetAdvertisementStatus();
    }

    setIsLoading(false);
  }

  function handleSelectEmail() {
    if (!currentEmailText) return;
    if (selectedEmails && selectedEmails.some((email) => email.label === currentEmailText)) return;

    const validationErrors = validateFieldsHelper(validationSchema, {
      currentEmailText,
    });
    setValidationErrors(validationErrors as any);
    if (Object.keys(validationErrors || {}).length) return;

    const createOption = (label: string) => ({
      label,
      value: label,
    });

    setSelectedEmails([...selectedEmails, createOption(currentEmailText)]);

    setCurrentEmailText("");
  }

  function findOrderType(orderTypeId: OrderTypeEnumValues) {
    if (orderTypeId === OrderTypeEnum.SELL) {
      return "saleIndicator";
    } else if (orderTypeId === OrderTypeEnum.AUCTION) {
      return "auctionIndicator";
    } else if (orderTypeId === OrderTypeEnum.BUY) {
      return "purchaseIndicator";
    }
  }

  return (
    <>
      <Heading order={2} styleOrder={11} tt="uppercase">
        {t("settings.advertisement")}
      </Heading>
      <SettingsFormContent>
        <Grid>
          <Grid.Row>
            <Grid.Col span={12}>
              <Text size="md" strong={true} display="title" tt="uppercase">
                {t("settings.sendNewsletter")}
              </Text>
              <Space height="20px" />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Stack gap="16px">
                <Group align="center">
                  <StepCount count={1} />
                  <Text size="sm" strong={true} display="title" tt="uppercase">
                    {t("settings.advertisingStep1")}
                  </Text>
                </Group>
                <Text size="sm" color="secondary">
                  {t("settings.advertisingStep1Text")}
                </Text>
              </Stack>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Spinner loading={isLoadingOrder} inline />
              {companyOrders?.orders && companyOrders.orders.length > 0 && (
                <table className="lightblue settings">
                  <thead>
                    <tr>
                      <th className="icon"></th>
                      <th className="icon"></th>
                      <th>{t("common.type")}</th>
                      <th>{t("common.amount")}</th>
                      <th>{t("common.treatment")}</th>
                      <th>{t("common.size")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyOrders?.orders.map((order: Order, index) => {
                      return (
                        <tr
                          id={`order-row-${index}`}
                          key={`order-row-${index}`}
                          className="clickable"
                          onClick={(e) => onClickCompanyOrder(order, e)}
                        >
                          <td>
                            <CheckBox checked={!!selectedOrders.find((_order) => _order.id === order.id)} />
                          </td>
                          <td>
                            <SVGAsset name={findOrderType(order.orderType.id)} />
                          </td>
                          <td>{t("common.plastic." + order.salesItem.salesItem)}</td>
                          <td>{order.remainingAmount}</td>
                          <td>{t("common.conditions." + order?.condition?.condition)}</td>
                          <td>{order?.size?.size}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Group align="center">
                <StepCount count={2} />
                <Text size="sm" strong={true} display="title" tt="uppercase">
                  {t("settings.advertisingStep2")}
                </Text>
              </Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={6}>
              <DropdownMulti
                dropDownClassNames="mbottom30"
                id="advertisement-multi"
                label={t("settings.recipients")}
                closeMenuOnSelect={false}
                options={companiesOptions}
                onChange={(e) => setSelectedCompanies(e)}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              <Group align="center">
                <StepCount count={3} />
                <Text size="sm" strong={true} display="title" tt="uppercase">
                  {t("settings.advertisingStep3")}
                </Text>
              </Group>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={6}>
              <TextfieldMulti
                label={t("commonComponents.emailAddresses")}
                keyDownHandler={handleSelectEmail}
                onInputChange={(e: any) => setCurrentEmailText(e)}
                onChange={(e: any) => setSelectedEmails(e)}
                inputValue={currentEmailText}
                value={selectedEmails}
                errormessage={validationErrors.currentEmailText}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col span={12}>
              {advertisementStatusMessage ? (
                <MessageBar type={advertisementStatusType} message={advertisementStatusMessage} />
              ) : null}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </SettingsFormContent>
      <SettingsFormCTAs>
        <CTA
          id="sendAdvertisement"
          size="lg"
          disabled={
            isLoading || selectedOrders.length === 0 || (selectedCompanies.length === 0 && selectedEmails.length === 0)
          }
          onClick={() => sendAdvertisementHandler()}
        >
          {t("common.send")}
        </CTA>
        <Spinner loading={isLoading} inline />
      </SettingsFormCTAs>
    </>
  );
}
