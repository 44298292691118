import PropTypes from "prop-types";
import SVGAsset from "../../../../components/SVGAsset/SVGAsset";
import { FC, PropsWithChildren } from "react";
import Heading from "../../atoms/Heading";

import styles from "./styles.module.css";

interface Props {
  open?: boolean;
  onClose?: () => void;
  isCloseVisible?: boolean;
  headerText?: string;
}

const Modal: FC<PropsWithChildren<Props>> = (props) => {
  const { open, onClose, isCloseVisible = true, headerText } = props;

  function closeDialog() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
      {!!open && (
        <div className={styles.modalRoot} id={"modal-dialog"}>
          <div className={styles.modalDialog}>
            {isCloseVisible && (
              <div className={`${headerText ? styles.closeDialogWithTitle : styles.closeDialog}`}>
                <Heading order={2} styleOrder={8} tt="uppercase">
                  {headerText}
                </Heading>
                <SVGAsset
                  className="close-x clickable"
                  name={`${headerText ? "close-white" : "close"}`}
                  onClick={() => closeDialog()}
                ></SVGAsset>
              </div>
            )}
            <div className={styles.modalBody}>{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalCTAs: FC<PropsWithChildren> = (props) => <div className={styles.modalCtas} {...props} />;
export const ModalContent: FC<PropsWithChildren<{ padded?: boolean }>> = ({ padded, ...props }) => (
  <div className={`${styles.modalContent} ${padded ? styles.padded : ""}`} {...props} />
);

export default Modal;

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  isCloseVisible: PropTypes.bool,
  onClose: PropTypes.func,
  headerText: PropTypes.string,
};
