import React from "react";

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  compact?: boolean;
  children?: React.ReactNode;
}

interface GridRowProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

interface GridColProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  span?: number;
  push?: number;
  className?: string;
}

// Grid component
const Grid: React.FC<GridProps> & { Row: React.FC<GridRowProps>; Col: React.FC<GridColProps> } = (props) => {
  const compact = props.compact ? "compact" : "";
  return (
    <div {...props} className={`grid ${compact} ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

// Grid.Row component
Grid.Row = (props) => {
  return (
    <div {...props} className={`row ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

// Custom range validation for `span` and `push` properties
const validateBetween = (value: number | undefined, from: number, to: number): boolean => {
  return typeof value === "number" && value >= from && value <= to;
};

// Grid.Col component
Grid.Col = (props) => {
  const { span, push, className, children, ...rest } = props;
  const optionalPush = push ? `at${push}` : "";

  // Validate `span` and `push`
  if (span && !validateBetween(span, 1, 12)) {
    throw new Error(`span must be between 1 and 12, but got ${span}`);
  }
  if (push && !validateBetween(push, 2, 11)) {
    throw new Error(`push must be between 2 and 11, but got ${push}`);
  }

  return (
    <div {...rest} className={`col${span} ${optionalPush} ${className || ""}`}>
      {children}
    </div>
  );
};

export default Grid;
