import React, { FC, useContext, useState } from "react";
import { postCreateFeedback } from "services/Feedback";
import { ErrorDialogContext } from "contexts/ErrorDialogContext";

import Feedback from "components/Feedback/Feedback";
import Spinner from "components/Spinner/Spinner";
import styles from "./../styles.module.css";

interface Props {
  orderId: number;
  taskId: number;
  transactionId: number;
  senderUserId: number;
  onCloseCB: () => void;
}

const FeedbackTask: FC<Props> = ({ taskId, transactionId, orderId, senderUserId, onCloseCB }) => {
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};
  const [loadingFeedback, setLoadingFeedback] = useState(false);

  async function submitFeedbackCB(stars: any, feedback: any) {
    setLoadingFeedback(true);
    try {
      await postCreateFeedback(taskId, transactionId, orderId, feedback, stars, senderUserId);
      setLoadingFeedback(false);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({
        message: "Submitting feedback failer, try again later.",
        error: error as Error,
      });
      showErrorDialog(true);
    }
  }

  return (
    <div className={styles.feedbackTask}>
      {<Spinner loading={loadingFeedback} inline={true} />}
      <Feedback submitFeedbackCB={submitFeedbackCB} />
    </div>
  );
};

export default FeedbackTask;
