import React, { FC, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorDialogContext } from "contexts/ErrorDialogContext";
import { postFinalAmount } from "services/Orders";

import TextField from "components/TextField/TextField";
import Spinner from "components/Spinner/Spinner";
import SVGAsset from "components/SVGAsset/SVGAsset";

import styles from "./../styles.module.css";
import Text from "components/_Reviver/atoms/Text";
import Group from "components/_Reviver/atoms/Group";
import CTA from "components/_Reviver/atoms/CTA";

interface Props {
  amount: number;
  amountDeviation?: number;
  orderId: number;
  taskId: number;
  onCloseCB: () => void;
}

const FinalAmountTask: FC<Props> = ({ amount, amountDeviation = 0, orderId, taskId, onCloseCB }) => {
  const { t } = useTranslation();
  const errorDialogContext = useContext(ErrorDialogContext);
  const { showErrorDialog = () => {}, setErrorDialogOptions = () => {} } = errorDialogContext || {};

  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [finalAmountError, setFinalAmountError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  async function saveFinalAmount() {
    setIsSaving(true);

    const deviation: number = (amount / 100) * amountDeviation;
    const lowEnd: number = amount - deviation;
    const highEnd: number = amount + deviation;

    if (finalAmount > highEnd || finalAmount < lowEnd) {
      setFinalAmountError(`${t("dashboard.legalFinalAmount")} ${lowEnd}kg - ${highEnd}kg`);
      setIsSaving(false);
      return;
    }
    // reset error if any
    if (finalAmountError) setFinalAmountError("");

    try {
      await postFinalAmount(orderId, finalAmount, taskId);
      onCloseCB();
    } catch (error) {
      setErrorDialogOptions({ message: "Saving final amount failed", error: error as Error });
      showErrorDialog(true);
    }
    setIsSaving(false);
  }

  return (
    <div className={styles.finalAmountTask}>
      <Group gap="10px">
        <SVGAsset name="final-amount" />
        <Text>{t("commonOrders.finalAmount")}</Text>
      </Group>
      <Group gap="10px" align="center">
        <TextField
          id="final-amount"
          type="number"
          value={finalAmount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFinalAmount(+e.target.value)}
        />
        <CTA id="final-amount-btn" onClick={() => saveFinalAmount()}>
          {t("common.save")}
        </CTA>
        <Spinner loading={isSaving} inline />
      </Group>
      {finalAmountError && <Text color="error">{finalAmountError}</Text>}
    </div>
  );
};

export default FinalAmountTask;
