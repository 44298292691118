import { FC } from "react";
import SVGAsset from "components/SVGAsset/SVGAsset";
import { saleOrPurchase } from "utils/Orders";
import { useTranslation } from "react-i18next";
import Dropdown from "components/Dropdown/Dropdown";
import { calculateDeviation } from "utils/Calculations";
import Group from "components/_Reviver/atoms/Group";
import Text from "components/_Reviver/atoms/Text";
import Grid from "components/Grid/Grid";
import Divider from "components/_Reviver/atoms/Divider";

interface Props {
  orderInDrawer: any;
  selectedAmountDrawer: any;
  amountOptionsDrawer: any;
  onChangeSelectedOrderAmountDrawer: any;
}

const OrderDetailsSell: FC<Props> = (props) => {
  const { orderInDrawer, selectedAmountDrawer, amountOptionsDrawer, onChangeSelectedOrderAmountDrawer } = props;
  const { t } = useTranslation();

  return (
    <>
      <Grid compact={true}>
        <Grid.Row>
          <Group align="center">
            <SVGAsset width="36" height="36" name={saleOrPurchase(t, orderInDrawer.orderType.id).tabActionSvgAsset} />
            <Text display="title" strong={true} size="md" tt="uppercase">
              {t("common.details")}
            </Text>
          </Group>
        </Grid.Row>
        <Grid.Row>
          <Text color="secondary">{t("common.amount")}</Text>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={3}>
            {selectedAmountDrawer && (
              <Dropdown
                id="active-orders-sidepanel-amount"
                menuPlacement="top"
                options={amountOptionsDrawer.map((option: any) => ({ value: option.value, label: option.content }))}
                selected={selectedAmountDrawer.value}
                onChange={(e: any) => onChangeSelectedOrderAmountDrawer(e)}
              />
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col span={12}>
            <Divider />
          </Grid.Col>
        </Grid.Row>
        <Grid.Col span={4}>
          {orderInDrawer.amountDeviation > 0 && (
            <p className="font-family-sans deviation-warning">
              {t("commonOrders.amountDeviationWarning") +
                " " +
                calculateDeviation(orderInDrawer.amount, orderInDrawer.amountDeviation)}
            </p>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};
export default OrderDetailsSell;
